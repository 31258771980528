<template>
  <BaseModal
    name="integration-google"
    id="integration-google"
    idModal="integration-google"
    size="sz"
    title="Conectar com o Google"
  >
    <div class="containder-grid">
      <div class="containder-grid">
        <p class="text-calendar">
          Você também deve criar uma agenda no ‘Google Calendar’ para vicular a
          mesma por aqui
        </p>
        <div id="googleLogin">
          <button @click="conectGoogle" id="login_button" class="btn">
            <img
              id="google_img"
              src="@/assets/img/icons/google.svg"
              width="18"
              height="15"
            />
            <span>
              {{
                google ? "Desconectar Google" : "Conectar com o Google"
              }}</span
            >
          </button>
        </div>
      </div>
    </div>
    <!-- SE EXISTIR LOADING-->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import GoogleService from "@/services/resources/GoogleService";
const serviceGoogle = GoogleService.build();
export default {
  data() {
    return {
      google: false,
      loading: false,
    };
  },
  computed: {
    user_id() {
      return this.$store.getters.user.user.id;
    },
  },
  methods: {
    conectGoogle() {
      if (this.google) {
        serviceGoogle
          .destroy({ id: "client/" + this.user_id })
          .then((resp) => {
            // console.log(resp);
            this.getGoogleIntegration();
          })
          .catch((err) => {
            // console.log(err);
            this.getGoogleIntegration();
          });
      } else {
        serviceGoogle
          .read({ id: "auth/geturl2" })
          .then((response) => {
            var url = response.url;
            url = url.replace('v2/', '');
            const popup = window.open(
              url,
              "janela",
              "width=895, height=690, top=30, left=200, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no"
            );
            var that = this;
            const popupTick = setInterval(function () {
              that.getGoogleIntegration();
              if (popup.closed) {
                that.getGoogleIntegration();
                clearInterval(popupTick);
                setTimeout(function () {
                  if (that.google === false) {
                    that.$bvToast.toast("Login não realizado", {
                      title: "Minha conta",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                  } else {
                    that.$bvToast.toast("Login Realizado com sucesso", {
                      title: "Minha conta",
                      variant: "info",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                    this.$bvModal.hide("integration-google");
                    this.$router.push({ name: "AgendamentosCriar" });
                  }
                }, 1000);
              }
            }, 500);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    },
    getGoogleIntegration() {
      serviceGoogle
        .read({ id: "client" })
        .then((resp) => {
          // console.log(resp);
          if (resp && resp[0] && resp[0] == "cliente não encontrado") {
            this.google = false;
            return;
          }
          this.google = true;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-calendar {
  text-align: center;
  color: var(--gray04);
}
.containder-grid {
  display: grid;
  justify-items: center;
}
#googleLogin button,
#googleLogout button {
  transition: 0.3s ease;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  border: 1px solid #e5e5e5;
  height: 50px;
  display: flex;
  font-size: 13px;
  border-radius: 10px;
  font-weight: 600;
}
#googleLogin img,
#googleLogout img {
  width: 18px;
  margin-right: 15px;
}

#googleLogin button:hover,
#googleLogout button:hover {
  background: #f2f2f2;
}
</style>
