<template>
  <BaseModal
    name="event-vizualize-calendar"
    id="event-vizualize-calendar"
    idModal="event-vizualize-calendar"
    size="lg"
    title="Dados do evento"
    @shown="getEvent"
  >
    <div>
      <div class="header">
        <div class="grid-gap">
          <h1>
            {{
              selectedEvent && selectedEvent.leads
                ? selectedEvent.leads[0].name
                : ""
            }}
          </h1>
          <div class="container-header">
            <div>
              <h2>Período</h2>
              <h3>{{ selectedEvent.duration }} min</h3>
            </div>
            <div>
              <h2>Horário</h2>
              <h3>
                {{ selectedEvent.start_time | moment("HH:mm") }} -
                {{ selectedEvent.end_time | moment("HH:mm") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="grid-gap">
          <BaseButton
            style="display: flex; align-items: center; gap: 10px"
            variant="primary"
            @click="confirmEvent"
          >
            <img
            style="filter: invert(1);"
              src="@/assets/icons/calendar/check-progress.svg"
              alt="trash_moda"
            />
            Concluir reunião
          </BaseButton>
          <BaseButton
            style="display: flex; justify-content: center; align-items: center; gap: 10px"
            variant="danger"
            @click="cancelEvent"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
            Cancelar
          </BaseButton>
        </div>
      </div>
      <div class="content">
        <div class="grid-gap" style="gap: 15px">
          <div>
            <h2>E-mail</h2>
            <h3>
              {{
                selectedEvent && selectedEvent.leads
                  ? selectedEvent.leads[0].email
                  : ""
              }}
            </h3>
          </div>
          <div>
            <h2>Fuso horário do convidado</h2>
            <h3>Horário de Brasilía</h3>
          </div>
        </div>
        <div class="config-color" :style="'background:' + filterDiv() + ';'">
          <span :style="'background:' + filterSpan() + ';'"></span>
        </div>
      </div>
      <div class="footer">
        Reunião adicionada em
        {{ selectedEvent.created_at | moment("DD [de] MMMM [de] YYYY") }}
      </div>
    </div>
    <template v-slot:footer="{}">
      <div></div>
    </template>
  </BaseModal>
</template>
<script>
import Vue from "vue";
//
import Multiselect from "vue-multiselect";
import AppointmentsService from "@/services/resources/AppointmentsService";
const serviceAppointments = AppointmentsService.build();
export default {
  props: ["selectedEvent", "calendars"],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedCalendar: null,
      loading: false,
    };
  },
  methods: {
    filterDiv() {
      if (this.selectedCalendar) {
        var meta_color = this.selectedCalendar.metas.find(
          (item) => item.meta_key === "color"
        );
        if (meta_color) {
          var parse_obj = JSON.parse(meta_color.meta_value);
          return parse_obj.background;
        } else {
          return "";
        }
      }
    },
    filterSpan() {
      if (this.selectedCalendar) {
        var meta_color = this.selectedCalendar.metas.find(
          (item) => item.meta_key === "color"
        );
        if (meta_color) {
          var parse_obj = JSON.parse(meta_color.meta_value);
          return parse_obj.color;
        } else {
          return "";
        }
      }
    },
    getEvent() {
      this.selectedCalendar = this.selectedEvent.calendar;
    },
    clear() {
      this.selectedCalendar = null;
      this.$bvModal.hide("event-vizualize-calendar");
    },
    confirmEvent() {
      Vue.swal({
        title: "Deseja confirmar essa reunião?",
        text: `Confirme se você deseja concluir este evento.`,
        type: "primary",
        showCancelButton: true,
        confirmButtonText: "Sim, concluir",
        cancelButtonText: "Não, cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: this.selectedEvent.id,
            status: "done",
          };
          serviceAppointments
            .update(data)
            .then(() => {
              this.$bvToast.toast("Evento cancelado com sucesso", {
                title: "Evento",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$parent.getEvents();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao cancelar evento", {
                title: "Evento",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.clear();
            });
        }
      });
    },
    cancelEvent() {
      Vue.swal({
        title: "Deseja continuar com o cancelamento desta reunião?",
        text: `Confirme se você deseja cancelar este evento. O convidado também receberá um e-mail de cancelamento.`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim, cancelar",
        cancelButtonText: "Não cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: this.selectedEvent.id,
            status: "inactive",
          };
          serviceAppointments
            .update(data)
            .then(() => {
              this.$bvToast.toast("Evento cancelado com sucesso", {
                title: "Evento",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$parent.getEvents();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao cancelar evento", {
                title: "Evento",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.clear();
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.config-color {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }
}
.blue_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  color: #2133d2;
  background: rgba(33, 51, 210, 0.3);
  transition: all 0.3s;
}
.red_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #ff0c37;
  background: rgba(255, 12, 55, 0.3);
}
.yellow_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #ffcb37;
  background: rgba(255, 203, 55, 0.3);
}
.blue_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #2ed7ee;
  background: rgba(46, 215, 238, 0.3);
}
.blue_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #5d21d2;
  background: rgba(93, 33, 210, 0.3);
}
.dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #17191f;
  background: rgba(0, 9, 22, 0.3);
}
.blue_dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #3483fa;
  background: rgba(52, 131, 250, 0.3);
}
.greenn_dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #00af7b;
  background: rgba(0, 175, 123, 0.3);
}
.greenn_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  color: #00e4a0;
  background: rgba(0, 228, 160, 0.3);
}
.footer {
  padding-top: 50px;
  color: var(--gray03);
}
.grid2 {
  p {
    color: var(--bluee);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: var(--blue-light);
    }
  }
}
.content {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-template-columns: 280px 1fr;
  padding-top: 20px;
}
.grid-gap {
  display: grid;
  gap: 10px;
  div {
    h2 {
      margin: 0;
      font-weight: 400;
      color: var(--gray03);
      font-size: 16px;
    }
    h3 {
      margin: 0;
      font-weight: 600;
      color: var(--gray01);
      font-size: 16px;
    }
    h4 {
      margin: 0;
      font-weight: 400;
      color: var(--gray01);
      font-size: 16px;
    }
    h5 {
      margin: 0;
      font-weight: 400;
      color: var(--gray03);
      font-size: 16px;
    }
  }
}
.container-header {
  display: grid;
  grid-template-columns: 80px 100px;
  div {
    h2 {
      margin: 0;
      font-weight: 400;
      color: var(--gray01);
      font-size: 16px;
    }
    h3 {
      margin: 0;
      font-weight: 400;
      color: var(--gray03);
      font-size: 16px;
      width: 220px;
    }
  }
}
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-template-columns: 280px 100px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-medium);
  div {
    h1 {
      margin: 0;
      color: var(--gray01);
      font-weight: 600;
      font-size: 24px;
    }
  }
}
</style>
